<template>
  <footer class=" p-4">
    Copyright © Awesomementor All rights reserved.
    <div class="">

    </div>

  </footer>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>

</style>
