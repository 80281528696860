import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import logger from './logger'
import VueCookies from 'vue3-cookies'
import VueFeather from 'vue-feather'
import PrimeVue from 'primevue/config'
import Button from 'primevue/button';
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmDialog from 'primevue/confirmdialog'
import Column from 'primevue/column';
import Chip from 'primevue/chip';
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
// import Editor from 'primevue/editor'
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import OverlayPanel from 'primevue/overlaypanel';
import Password from 'primevue/password';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast'
import Toolbar from 'primevue/toolbar';
import TreeTable from 'primevue/treetable';

import VueSidebarMenu from 'vue-sidebar-menu'
import { Form, Field } from "vee-validate";

// import customQuillModule from 'customQuillModule'
// Quill.register('modules/customQuillModule', customQuillModule)
// import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css';


// scss build
// import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primevue/resources/primevue.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.min.css'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import '@/assets/scss/main.scss'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


const app = createApp(App)
app.config.globalProperties.$filters = {
    makeComma(value) {
        if (value) {
            return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
}
app.component(VueFeather.name, VueFeather)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Button', Button)
app.component('ConfirmDialog', ConfirmDialog)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Column', Column)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Chip', Chip)
app.component('DataTable', DataTable)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Dialog', Dialog)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Divider', Divider)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Dropdown', Dropdown)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Form', Form);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Field', Field);

// app.component('QuillEditor', Editor)
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('InputSwitch', InputSwitch)
app.component('OverlayPanel', OverlayPanel)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Password', Password)
app.component('RadioButton', RadioButton)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Textarea', Textarea)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Toast', Toast)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Toolbar', Toolbar)
app.component('TreeTable', TreeTable)
// eslint-disable-next-line vue/multi-word-component-names
app.component('Datepicker', Datepicker);

app.use(router)
  .use(logger)
  .use(VueCookies)
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(ToastService)
  .use(VueSidebarMenu)
  .mount('#app')
