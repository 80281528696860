<template>
  <div class="layout-wrapper layout-menu-static layout-menu-active">
    <app-header />
    <div class="menu-wrapper" :class="isMenu ? 'menu-active' : ''">
<!--      <sidebar-menu :menu="menu" />-->
<!--      <sidebar-menu :menu="menuItems" />-->
      <SideMenu class="layout-menu-container" />
      <!--    <Menu :model="items" class="menu-wrapper" />-->


    </div>
    <div class="layout-main">
      <div class="layout-content">
        <router-view />
      </div>

<!--      <div class="card">-->
<!--        <div class="flex card-container blue-container overflow-hidden">-->
<!--          <div class="flex-none flex align-items-center justify-content-center bg-blue-500 font-bold text-white m-2 px-5 py-3 border-round">PrimeFlex</div>-->
<!--          <div class="flex-grow-1 flex align-items-center justify-content-center bg-blue-500 font-bold text-white m-2 px-5 py-3 border-round">PrimeFlex</div>-->
<!--          <div class="flex-none flex align-items-center justify-content-center bg-blue-500 font-bold text-white m-2 px-5 py-3 border-round">PrimeFlex</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <a @click="menuToggle">메뉴닫기</a>-->

    </div>
    <app-footer />
  </div>
<!--  layout-wrapper layout-menu-light layout-topbar-blue layout-menu-static layout-menu-active p-ripple-disabled-->
<!--  layout-wrapper layout-menu-light layout-topbar-blue layout-menu-static p-ripple-disabled-->




</template>

<script>
import SideMenu from "@/components/SideMenu";
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
export default {
  name: "DefaultLayout",
  // eslint-disable-next-line vue/no-unused-components
  components: {AppFooter, AppHeader, SideMenu},
  data() {
    return {
      isMenu: true,
      menu: [
        {
          title: '사용자관리',
          href: '/userList',
        },
      ],
      items: [
        {
          label: '사용자관리',
          icon: 'pi pi-user',
          to: '/userList'
        },
        {
          label: '아이템관리',
          icon: 'pi pi-gift',
          to: '/itemList'
        }
      ]
    }
  },
  methods: {
    menuToggle() {
      this.isMenu = !this.isMenu
    }
  }
}
</script>

<style scoped>

</style>
