<template>
  <div>
<!--    <font-awesome-icon icon="gift" /> add-->
<!--    <font-awesome-icon icon="gift" />-->
<!--    <vue-feather type="home"></vue-feather>-->
<!--    <vue-feather type="user"></vue-feather>-->
<!--    <vue-feather type="gift"></vue-feather>-->
<!--    <vue-feather type="credit-card"></vue-feather>-->
<!--    <vue-feather type="activity"></vue-feather>-->
<!--    <vue-feather type="globe"></vue-feather>-->
<!--    <vue-feather type="message-circle" />-->
<!--    <vue-feather type="help-circle" />-->
<!--    <vue-feather type="info" />-->
    <sidebar-menu :menu="menuItems" theme="white-theme" hideToggle width="auto" relative @item-click="onItemClick">
    </sidebar-menu>
<!--    <PanelMenu :model="menuItems" />-->
  </div>

</template>

<script>
// import { SidebarMenu } from 'vue-sidebar-menu'
// import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

// import {library} from "@fortawesome/fontawesome-svg-core";
// import {
//   far
// } from '@fortawesome/free-regular-svg-icons'
// import {
//   faGift
// } from '@fortawesome/free-solid-svg-icons'
// library.add(far)
// // library.add(fas)
// library.add(faGift)
export default {
  name: "SideMenu",
  components: {
    // SidebarMenu
  },
  data() {
    return {
      menuItemsb: [
        {
          title: '사용자관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'user' },
          },
          href: '/userList',
        },
      ],
      menuItems: [
        // {
        //   title: '관리자관리',
        //   icon: {
        //     element: 'vue-feather',
        //     attributes: { type: 'users' },
        //   },
        //   href: '/managerList',
        // },
        {
          title: '사용자관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'user' },
          },
          href: '/userList',
        },
        // {
        //   title: '아이템관리',
        //   icon: {

        //     element: 'vue-feather',
        //     attributes: { type: 'gift' },
        //   },
        //   href: '/itemList',
        // },
        {
          title: '결제관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'credit-card' },
          },
          href: '/paymentList',
        },
        {
          title: '어썸캐시관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'dollar-sign' },
          },
          href: '/cashList',
        },
        {
          title: '멤버쉽구독내역',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'award' },
          },
          href: '/subscribeList',
        },
        {
          title: '환불관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'corner-down-left' },
          },
          href: '/refundList',
        },
        // {
        //   title: '캐시충전내역',
        //   icon: {
        //     element: 'vue-feather',
        //     attributes: { type: 'dollar-sign' },
        //   },
        //   href: '/cashList',
        // },
        {
          title: '분류관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'list' },
          },
          href: '/categoryList',
        },
        {
          title: '활동관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'activity' },
          },
          href: '/activityList',
        },
        {
          title: '서비스관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'globe' },
          },
          href: '/serviceList',
        },
        {
          title: '채팅관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'message-circle' },
          },
          href: '/chattingList',
        },
        // {
        //   title: '채팅관리',
        //   icon: {
        //     element: 'vue-feather',
        //     attributes: { type: 'message-circle' },
        //   },
        //   href: '/chatList',
        // },
        {
          title: '온디맨드관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'help-circle' },
          },
          href: '/ondemandList',
        },
        {
          title: '정보관리',
          icon: {
            element: 'vue-feather',
            attributes: { type: 'info' },
          },
          child: [
            {
              title: '공지사항',
              href: '/noticeList'
            },
            // {
            //   title: 'FAQ',
            // },
            {
              title: '사이트정책관리',
              href: '/PolicyList'
            },
          ]
        },
        // {
        //   title: '메세지발송관리',
        //   icon: {
        //     element: 'vue-feather',
        //     attributes: { type: 'message-square' },
        //   },
        //   child: [
        //     {
        //       title: '그룹관리',
        //       href: '/groupList'
        //     },
        //     {
        //       title: '문자발송',
        //       href: '/smsList'
        //     },
        //     {
        //       title: '이메일발송',
        //       href: '/emailList'
        //     }
        //   ]
        // },
      ]
    }
  },
  methods : {
    onItemClick(event, item) {
      this.$log.debug(['onItemClick', event.currentTarget, item]);
      event.currentTarget.classList.add('menu-active')

    }
  },
  mounted() {
    // 사용자 정보를 확인한다.
    if (this.$cookies.get('accessToken')) {
      const user = JSON.parse(localStorage.getItem('userInfo'));
      this.$log.debug(['=== side menu user', user]);
      if (user.user_type === 'USER_TYPE99') {

        const a = this.menuItems;
        const b = [
            {
              title: '공통코드관리',
              icon: {
                element: 'vue-feather',
                attributes: { type: 'code' },
              },
              href: '/codeList',
            }
        ];

        this.menuItems = a.concat(b);
        this.$log.debug(['use_type', user.user_type, a, b, ]);
        // this.menuItems = this.menuItems.push({
        //   title: '공통코드관리',
        //   icon: {
        //     element: 'vue-feather',
        //     attributes: { type: 'code' },
        //   },
        //   href: '/codeList',
        // });
      }
    }
  }
}
</script>

<style scoped>

</style>
