<template>
  <div class="layout-topbar">
    <div class="layout-topbar-left"><img src="@/assets/images/full-logo.png" class="img-fluid"></div>
<!--    <div class="layout-topbar-right"><vue-feather type="align-left" @click="toggle_sidebar" /> </div>-->
    <div class="layout-topbar-right">관리자님 환영합니다. <span @click="logout">로그아웃</span> </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      sidebar: true,
    }
  },
  methods: {
    // toggle_sidebar() {
    //   this.sidebar = !!this.sidebar;
    // },
    logout() {
      this.$log.debug(['logout'])
      localStorage.removeItem('userInfo')
      this.$cookies.remove('accessToken')
      this.$cookies.remove('refreshToken')
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>

</style>
