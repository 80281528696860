import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '@/components/DefaultLayout';
import VueCookies from 'vue-cookies'

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    redirect: '/userList',
    children: [
      {
        path: '/userList',
        name: 'userList',
        component: () => import('@/views/user/UserList')
      },
      {
        path: '/itemList',
        name: 'itemList',
        component: () => import('@/views/item/ItemList')
      },
      {
        path: '/categoryList',
        name: 'categoryList',
        component: () => import('@/views/category/CategoryList')
      },
      {
        path: '/paymentList',
        name: 'paymentList',
        component: () => import('@/views/payment/PaymentList')
      },
      {
        path: '/subscribeList',
        name: 'subscribeList',
        component: () => import('@/views/subscribe/SubscribeList')
      },
      {
        path: '/refundList',
        name: 'refundList',
        component: () => import('@/views/refund/RefundList')
      },
      {
        path: '/cashList',
        name: 'cashList',
        component: () => import('@/views/cash/CashList')
      },
      {
        path: '/activityList',
        name: 'activityList',
        component: () => import('@/views/activity/ActivityList')
      },
      {
        path: '/activityAdd',
        name: 'activityAdd',
        component: () => import('@/views/activity/ActivityAdd')
      },
      {
        path: '/serviceList',
        name: 'serviceList',
        component: () => import('@/views/service/ServiceList')
      },
      {
        path: '/chattingList',
        name: 'chattingList',
        component: () => import('@/views/chatting/ChattingList')
      },
      {
        path: '/ondemandList',
        name: 'ondemandList',
        component: () => import('@/views/ondemand/OndemandList')
      },
      {
        path: '/noticeList',
        name: 'noticeList',
        component: () => import('@/views/notice/NoticeList')
      },
      {
        path: '/policyList',
        name: 'policyList',
        component: () => import('@/views/policy/PolicyList')
      },
      {
        path: '/codeList',
        name: 'codeList',
        component: () => import('@/views/code/CodeList')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login')
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach((to
    , from, next) => {
  console.log(['each', VueCookies.get('accessToken'), to.path, to.name]);

  if (to.name === 'login') {
    if (VueCookies.get('accessToken')) {
      next('/');
    }
    next();
  } else {
    if (VueCookies.get('accessToken')) {
      next()
    } else {
      // logout 처리는?
      localStorage.removeItem('userInfo')
      VueCookies.remove('accessToken')
      VueCookies.remove('refreshToken')
      next('/login')
    }
  }
})

export default router
